<template>
  <v-dialog
    v-model="showDialogForm"
    persistent
    max-width="600px"
  >
    <v-card v-if="typeof currentCustomer !== 'undefined'">
      <v-card-title><span class="headline">{{ $t("Participant") }}</span></v-card-title>
      <v-card-text>
        <ParticipantForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :current-participants="currentParticipants"
          :is-no-online="isNoOnline"
          :current-customer="currentCustomer"
          ref="participantDialogForm"
        />
        <v-row v-if="false">
          <v-col cols="12">
            <DiscountListAndAdd
              :default-item-props="{ participant: this.item['@id'] }"
              :show-message="showMessage"
              :discounts="this.item.discounts"
              :show-title="false"
              :is-discountable="this.currentSeminarSettings.individuallyBookable"
              :invoice="this.item.firstInvoice"
              object-type="participant"
              @updateDiscounts="updateDiscountsHandler"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="showDialogForm = false"
        >{{ $t("Close") }}</v-btn>
        <v-spacer />
        <v-btn
          class="mainAccent lighten-3"
          @click="resetForm"
        >{{ $t("Reset") }}</v-btn>
        <v-btn
          color="primary"
          @click="sendForm"
        >{{ $t("Submit") }}</v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoading" />
  </v-dialog>
</template>

<script>
  import { createHelpers } from "vuex-map-fields";
  import ParticipantForm from "./Form";
  import NotificationMixin from "../../mixins/NotificationMixin";
  import Loading from "@/components/Loading";
  import { mapActions, mapGetters } from "vuex";
  import DialogMixin from "@/mixins/DialogMixin";
  import DiscountListAndAdd from "@/components/discount/ListAndAdd";
  import TenantMixin from "@/mixins/TenantMixin";
  import CustomerMixin from "@/mixins/CustomerMixin";

  const servicePrefix = "Participant";

  const { mapFields } = createHelpers({
    getterType: "participant/getField",
    mutationType: "participant/updateField",
  });

  export default {
    name: "ParticipantDialog",
    servicePrefix,
    mixins: [DialogMixin, NotificationMixin, TenantMixin, CustomerMixin],
    components: {
      Loading,
      ParticipantForm,
      DiscountListAndAdd
    },
    props: {
      value: Boolean,
      handleCreated: {
        type: Function,
        required: false,
      },
      handleUpdated: {
        type: Function,
        required: false,
      },
      participantItem: {
        type: Object,
        required: false,
      },
      seminarPackageId: {
        type: String,
        required: true,
      },
      currentParticipants: {
        type: Array,
        required: false,
      },
      isNoOnline: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        item: {},
        showDiscountDialogForm: false,
        createMessage: this.$t('ParticipantAdded'),
        updateMessage: this.$t('ParticipantUpdated'),
      };
    },
    computed: {
      ...mapFields([
        "error",
        "isLoading",
        "created",
        "violations",
        "updated",
        "byId",
      ]),
      ...mapGetters("participant", ["find"]),
      showDialogForm: {
        get() {
          return this.value;
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        },
      },
      currentCustomer() {
        if (this.item.customer) {
          return this.resolveCustomer(this.item.customer)
        }
        return null;
      }
    },
    methods: {
      ...mapActions("participant", {
        create: "create",
        update: "update",
        resetData: "resetData",
        retrieve: "load",
      }),
      sendForm() {
        if (this.isValidForm()) {
          let model = this.$refs.participantDialogForm.$v.item.$model;
          if (this.handleUpdated && model["@id"]) {
            this.update(model);
          }
          if (this.handleCreated && !model["@id"]) {
            model["seminarPackage"] = this.seminarPackageId;
            this.create(model);
          }
        }
      },
      reset() {
        this.$refs.participantDialogForm.$v.$reset();
      },
      resetForm() {
        this.item = { ...this.participantItem };
        if (this.$refs["participantDialogForm"]) {
          this.$refs.participantDialogForm.$v.$reset();
        }
      },
      isValidForm() {
        this.$refs.participantDialogForm.$v.$touch();
        return !this.$refs.participantDialogForm.$v.$invalid;
      },
      updateDiscountsHandler(updatedDiscounts) {
        this.item.discounts = updatedDiscounts;
        this.$emit('updatedDiscounts');
      },
    },
    watch: {
      participantItem() {

        this.item = { ...this.participantItem };
        this.resetForm();
      },
      created(created) {
        console.log("hallo?");
        if (!created) {
          return;
        }
        if (!this.elementForDialog(created, this.item)) {
          return;
        }
        this.showMessage(this.createMessage);
        if (this.handleCreated) {
          this.handleCreated(this.created);
          this.showDialogForm = false;
        }
      },
      updated(updated) {
        if (!this.elementForDialog(updated, this.item)) {
          return;
        }
        this.showMessage(this.updateMessage);
        if (this.handleUpdated) {
          this.handleUpdated(this.updated);
          this.showDialogForm = false;
        }
      },
      error(message) {
        message && this.showError(message);
      },
    },
    created() {
      this.item = { ...this.participantItem };
    },
  };
</script>
