<template>
  <v-form>
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
    >
      <v-card-title>{{ $t('Company Settings') }}</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-select
                :error-messages="typeErrors"
                :label="$t('addressType')"
                @blur="$v.item.address.type.$touch()"
                @input="$v.item.address.type.$touch()"
                required
                :items="typeValues"
                v-model="item.address.type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-text-field
                :error-messages="streetNrErrors"
                :label="$t('streetNr')"
                @blur="$v.item.address.streetNr.$touch()"
                @input="$v.item.address.streetNr.$touch()"
                v-model="item.address.streetNr"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-text-field
                :error-messages="additionalErrors"
                :label="$t('additional')"
                @blur="$v.item.address.additional.$touch()"
                @input="$v.item.address.additional.$touch()"
                v-model="item.address.additional"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="4"
            >
              <v-text-field
                :error-messages="zipCodeErrors"
                :label="$t('zipCode')"
                @blur="$v.item.address.zipCode.$touch()"
                @input="$v.item.address.zipCode.$touch()"
                required
                v-model="item.address.zipCode"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="8"
            >
              <v-text-field
                :error-messages="cityErrors"
                :label="$t('city')"
                @blur="$v.item.address.city.$touch()"
                @input="$v.item.address.city.$touch()"
                v-model="item.address.city"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-text-field
                :label="$t('country')"
                v-model="defaultCountry"
                disabled="disabled"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-file-input
                :rules="imageRules"
                :label="$t('logo')"
                :placeholder="item.logo
                  ? item.logo.name
                  : $t('logo')
                  "
                v-model="logoUpload"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-avatar
                v-if="item.logo.contentUrl"
                size="90"
              >
                <v-img :src="documentEntryPoint + item.logo.contentUrl"></v-img>
              </v-avatar>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
            > </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-file-input
                :rules="imageRules"
                :label="$t('letterPaperFirst')"
                :placeholder="item.letterPaperFirst
                  ? item.letterPaperFirst.name
                  : $t('letterPaperFirst')
                  "
                v-model="letterPaperFirstUpload"
                accept="application/pdf"
                prepend-icon="mdi-email-variant"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
            > </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-file-input
                :rules="imageRules"
                :label="$t('letterPaperFurther')"
                :placeholder="item.letterPaperFurther
                  ? item.letterPaperFurther.name
                  : $t('letterPaperFurther')
                  "
                v-model="letterPaperFurtherUpload"
                accept="application/pdf"
                prepend-icon="mdi-email-variant"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
            > </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <v-text-field
                :error-messages="colorErrors"
                :label="$t('tenantColor')"
                @blur="$v.item.color.$touch()"
                @input="$v.item.color.$touch()"
                v-model="item.color"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-checkbox
                :label="$t('isInhouse')"
                @blur="$v.item.enabled.$touch()"
                @input="$v.item.enabled.$touch()"
                v-model="item.inHouse"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import has from "lodash/has";
  import { required } from "vuelidate/lib/validators";
  import DocumentMixin from '../../mixins/DocumentMixin';
  export default {
    name: "CompanySettingForm",
    mixins: [validationMixin, DocumentMixin],
    props: {
      values: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => { },
      },

      initialValues: {
        type: Object,
        default: () => { },
      },

      handleSubmit: {
        type: Function,
        required: false,
      },

      handleReset: {
        type: Function,
        required: false,
      },
    },
    mounted() { },
    data() {
      return {
        address: {
          type: null,
          streetNr: null,
          additional: null,
          zipCode: null,
          city: null,
          country: 'DE',
        },
        defaultCountry: 'DE',
        typeValues: [
          { text: this.$t("privateAddress"), value: "private" },
          { text: this.$t("businessAddress"), value: "business" },
          { text: this.$t("invoiceAddress"), value: "invoice" },
          { text: this.$t("deliveryAddress"), value: "delivery" },
        ],
        logo: null,
        letterPaperFirst: null,
        letterPaperFurther: null,
        logoUpload: null,
        letterPaperFirstUpload: null,
        letterPaperFurtherUpload: null,
        imageRules: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2 MB!",
        ]
      };
    },
    computed: {
      item() {
        return this.initialValues || this.values;
      },
      typeErrors() {
        const errors = [];

        if (!this.$v.item.address.type.$dirty) {
          return errors;
        }

        has(this.violations, "type") && errors.push(this.violations.address.type);

        !this.$v.item.address.type.required &&
          errors.push(this.$t("Field is required"));

        return errors;
      },
      streetNrErrors() {
        const errors = [];

        if (!this.$v.item.address.streetNr.$dirty) {
          return errors;
        }

        has(this.violations, "streetNr") &&
          errors.push(this.violations.address.streetNr);

        !this.$v.item.address.streetNr.required &&
          errors.push(this.$t("Field is required"));

        return errors;
      },
      additionalErrors() {
        const errors = [];

        if (!this.$v.item.address.additional.$dirty) {
          return errors;
        }
        has(this.violations, "additional") &&
          errors.push(this.violations.address.additional);

        return errors;
      },
      zipCodeErrors() {
        const errors = [];

        if (!this.$v.item.address.zipCode.$dirty) {
          return errors;
        }
        has(this.violations, "zipCode") &&
          errors.push(this.violations.address.zipCode);
        !this.$v.item.address.zipCode.required &&
          errors.push(this.$t("Field is required"));

        return errors;
      },
      cityErrors() {
        const errors = [];

        if (!this.$v.item.address.city.$dirty) {
          return errors;
        }
        has(this.violations, "city") && errors.push(this.violations.address.city);
        !this.$v.item.address.city.required &&
          errors.push(this.$t("Field is required"));

        return errors;
      },
      colorErrors() {
        const errors = [];

        if (!this.$v.item.color.$dirty) {
          return errors;
        }
        has(this.violations, "additional") &&
          errors.push(this.violations.color);

        return errors;
      },
      violations() {
        return this.errors || {};
      },
    },
    methods: {
      submitItem() {
        if (this.handleSubmit) {
          this.handleSubmit();
        }
      },
      resetItem() {
        if (this.handleReset) {
          this.handleReset();
        }
      },
      log(m) {
        console.log(m, "#######");
      },
    },
    validations: {
      item: {
        address: {
          type: {
            required,
          },
          streetNr: {
            required,
          },
          additional: {},
          zipCode: {
            required,
          },
          city: {
            required,
          },
        },
        logo: {},
        letterPaperFirst: {},
        letterPaperFurther: {},
        color: {},
      },
    },
  };
</script>
