<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="380px"
    :key="participant['@id']"
  >
    <v-card v-if="postalScriptAddress">
      <v-card-title>Script per Post an:</v-card-title>
      <v-card-text>
        <template v-if="postalScriptAddress.recipientName">
          <strong>RP: {{ postalScriptAddress.recipientName }}</strong><br />
        </template>
        <template v-else>
          <template v-if="profileCompany">
            <strong>{{ profileCompany }}</strong><br />
          </template>
          <template v-else-if="profileName">
            <strong>{{ profileName }}<br /></strong>
          </template>
          <template v-else>
            <strong>{{ participant.name }}<br /></strong>
          </template>
        </template>
        <template v-if="postalScriptAddress.additional">
          <strong>{{ postalScriptAddress.additional }}</strong><br />
        </template>
        {{ postalScriptAddress.streetNr }}<br />
        {{ postalScriptAddress.zipCode }} {{ postalScriptAddress.city }}<br />
        {{ postalScriptAddress.country }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
        >{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AddressMixin from "@/mixins/AddressMixin";
  import ProfileMixin from '@/mixins/ProfileMixin';
  import CustomerMixin from '@/mixins/CustomerMixin';

  export default {
    name: "ScriptPerPostInfoDialog",
    mixins: [AddressMixin, ProfileMixin, CustomerMixin],
    props: {
      value: Boolean,
      participant: {
        type: Object,
        required: true,
      },
    },
    computed: {
      showDialog: {
        get() {
          return this.value;
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        },
      },
      postalScriptAddress() {
        if (this.participant['@id'] && this.participant.postalScriptAddress) {
          return this.resolveAddress(this.participant.postalScriptAddress);
        }
        return null;
      },
      profileCompany() {
        if (this.participant.customer && this.customer) {
          return this.getProfileItemBasics(this.customer.profile, 'company');
        }
        if (this.participant.profile) {
          return this.getProfileItemBasics(this.participant.profile, 'company');
        }
        return null;
      },
      profileName() {
        if (this.participant.customer && this.customer) {
          return this.getProfileName(this.customer.profile);
        }
        if (this.participant.profile) {
          return this.getProfileName(this.participant.profile);
        }
        return null;
      },
      customer() {
        if (this.participant.customer) {
          return this.resolveCustomer(this.participant.customer);
        }
        return null;
      }
    },
  };
</script>
