<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onUpdateTenantCompanySettingForm"
    />
    <TenantCompanySettingForm
      :errors="violations"
      :values="item"
      ref="createForm"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { createHelpers } from "vuex-map-fields";
  import TenantCompanySettingForm from "../../components/tenantCompanySetting/Form.vue";
  import Loading from "@/components/Loading";
  import Toolbar from "@/components/Toolbar";
  import CreateMixin from "../../mixins/CreateMixin";
  import DocumentMixin from "../../mixins/DocumentMixin";

  const servicePrefix = "TenantCompanySetting";

  const { mapFields } = createHelpers({
    getterType: "tenantCompanySetting/getField",
    mutationType: "tenantCompanySetting/updateField",
  });

  export default {
    name: "TenantCompanySettingCreate",
    servicePrefix,
    mixins: [CreateMixin, DocumentMixin],
    data() {
      return {
        item: {
          address: {},
          logo: {},
          letterPaperFirst: {},
          letterPaperFurther: {},
        },
        createMessage: this.$t("tenantCompanySettingCreateSuccess"),
        uploads: [],
      };
    },
    components: {
      Loading,
      Toolbar,
      TenantCompanySettingForm,
    },
    watch: {
      uploadedDocument() {
        if (!this.uploadedDocument) {
          return;
        }
        switch (this.uploadedDocument["name"]) {
          case this.$refs.createForm.logoUpload.name:
            this.item.logo = this.uploadedDocument["@id"];
            this.uploads.logo = 0
            break;
          case this.$refs.createForm.letterPaperFirstUpload.name:
            this.item.letterPaperFirst = this.uploadedDocument["@id"];
            this.uploads.letterPaperFirst = 0
            break;
          case this.$refs.createForm.letterPaperFurtherUpload.name:
            this.item.letterPaperFurther = this.uploadedDocument["@id"];
            this.uploads.letterPaperFurther = 0
            break;
        }

        if (!this.uploads.logo && !this.uploads.letterPaperFirst && !this.uploads.letterPaperFurther) {
          this.onSendForm()
        }
      },
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'violations'])
    },
    methods: {
      ...mapActions("tenantCompanySetting", {
        retrieve: "load",
        create: "create",
      }),
      onUpdateTenantCompanySettingForm() {
        if (
          (this.$refs.createForm.logoUpload ||
            this.$refs.createForm.letterPaperFirstUpload ||
            this.$refs.createForm.letterPaperFurtherUpload)
        ) {
          if (this.$refs.createForm.logoUpload) {
            this.uploads["logo"] = 1;
            this.uploadDocument(this.$refs.createForm.logoUpload);
          }

          if (this.$refs.createForm.letterPaperFirstUpload) {
            this.uploads["letterPaperFirst"] = 1;
            this.uploadDocument(this.$refs.createForm.letterPaperFirstUpload, true);
          }

          if (this.$refs.createForm.letterPaperFurtherUpload) {
            this.uploads["letterPaperFurther"] = 1;
            this.uploadDocument(this.$refs.createForm.letterPaperFurtherUpload, true);
          }
          return;
        }
        this.onSendForm();
      },
    },
  };
</script>
