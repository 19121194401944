<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onCreateSeminarPackage"
      :handle-copy="copy"
      :enableInvoiceApproval="true"
      :approveInvoice="approveInvoice"
      :enableSubmit="!blockEditing"
      :enableReset="!blockEditing"
    />
    <SeminarPackageForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      refForm="updateForm"
      :loading="isLoading"
    />
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
    >
      <v-row>
        <v-col cols="12">
          <v-card
            outlined
            tile
          >
            <v-card-text>
              <ParticipantListAndAddPackage
                ref="participantListPackage"
                :is-public-bookable="true"
                :participants="item.participants"
                :locked="blockAddParticipants"
                :seminar-package="item"
                @updateParticipants="updateParticipantsHandler"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import SeminarPackageForm from '../../components/seminarPackage/Form.vue';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import CreateMixin from '../../mixins/CreateMixin';
  import UpdateMixin from '../../mixins/UpdateMixin';
  import ParticipantListAndAddPackage from '@/components/participant/ListAndAddPackage';

  import { mapGetters } from 'vuex';

  const servicePrefix = 'SeminarPackage';

  const { mapFields } = createHelpers({
    getterType: 'seminarPackage/getField',
    mutationType: 'seminarPackage/updateField'
  });

  export default {
    name: 'SeminarPackageUpdate',
    servicePrefix,
    mixins: [CreateMixin, UpdateMixin],
    components: {
      Loading,
      Toolbar,
      SeminarPackageForm,
      ParticipantListAndAddPackage
    },
    data() {
      return {
        item: {},
        parent: {}
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'deleted', 'violations', 'handled', 'handleAction']),
      ...mapFields('seminarPackage', {
      }),
      ...mapGetters('seminarPackage', ['find']),
      blockEditing() {
        return this.item && this.item.participants && this.item.participants.length > 0 || false;
      },
      blockAddParticipants() {
        return false; // TODO: watch for seminar start dates, if first started, block
      },
    },
    methods: {
      ...mapActions('seminarPackage', {
        create: 'create',
        retrieve: 'load',
        update: "update",
        actionSeminarPackage: 'action'
      }),
      reloadSeminarPackage() {
        // refetch seminar
        return this.retrieve(decodeURIComponent(this.item['@id']));
      },
      onCreateSeminarPackage() {
        if (this.$route.params.parent) {
          this.$refs.createForm.item.parent = this.$route.params.parent;
        }
        this.onSendForm();
      },
      copy() {
        this.actionSeminarPackage({ item: this.item, action: 'copy' });
      },
      approveInvoice() {
        this.$confirm(this.$t('InvoiceApprovalSeminarPackage'), {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No'),
        }).then((response) => {
          if (!response) {
            return;
          }
          this.actionSeminarPackage({ item: this.item, action: 'generateAndSendParticipantsInvoice' });
        });
      },
      updateParticipantsHandler(updatedParticipants) {
        this.item.participants = updatedParticipants;
        this.reloadSeminarPackage();
      },
    },
    created() {
      this.parent = this.$route.params.parent ? this.find(this.$route.params.parent) : {};
    },
    watch: {
      handled() {
        if (this.handleAction === 'copy') {
          this.showMessage(this.$t('SeminarPackageCopied'));
          this.$router.push({
            name: 'SeminarPackageUpdate',
            params: { id: this.handled['@id'] }
          });
          this.handleAction = null;
          this.handled = null;
        }
        if (this.handleAction === 'generateAndSendParticipantsInvoice') {
          this.showMessage(this.$t('SeminarPackageInvoiceApproved'));
          this.handleAction = null;
          this.handled = null;
        }
      }
    },
  };
</script>
