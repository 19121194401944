<template>
  <div>
    <v-card
      v-for="address in value"
      :key="address.id"
      class="mt-5"
    >
      <v-card-text>
        <template v-if="address.recipientName"><strong>{{ address.recipientName }}</strong><br /></template>
        <template v-if="address.additional"><strong>{{ address.additional }}</strong><br /></template>
        {{ address.streetNr }}<br />
        {{ address.zipCode }} {{ address.city }}<br />
        {{ address.country }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          small
          text
          @click.prevent="dialogControl(address.id)"
        >
          {{ $t('Edit') }}
        </v-btn>
        <AddressDialog
          v-model="showAddressDialogForm"
          :address-item="address"
          :handle-updated="onAddressUpdated"
          :title="$t('EditAddress')"
          @close="dialogControl(address.id, true)"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  import AddressDialog from '../../components/address/AddressDialog';

  export default {
    name: 'AddressCard',
    components: {
      AddressDialog
    },
    props: {
      value: {
        type: Array,
      },
    },
    data() {
      return {
        showAddressDialogForm: [],
        showTest: false,
      }
    },
    methods: {
      onAddressUpdated(updatedAddress) {
        console.log("updated address: " + updatedAddress.streetNr);
      },
      dialogControl(id, close = false) {
        if (!close) {
          this.showAddressDialogForm.push(id);
        } else {
          this.showAddressDialogForm.splice(this.showAddressDialogForm.indexOf(id), 1);
        }
      },
    },
  };
</script>
