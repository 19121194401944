<template>
  <div>
    <Toolbar :handle-edit="() => editHandler(item)" :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
            `${ $options.servicePrefix } ${ item["@id"] }`
          }}
        </v-toolbar-title>
      </template>
    </Toolbar>
    <br />
    <div v-if="item">
      <v-card max-width="860px" class="mt-4 mx-auto">
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            {{ $t("Profile") }}
            <v-icon>mdi-account</v-icon>
          </v-tab>
          <v-tab>
            {{ $t("Seminare") }}
            <v-icon>mdi-home-city</v-icon>
          </v-tab>
          <v-tab>
            {{ $t("Billing") }}
            <v-icon>mdi-cash</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat class="mt-5">
              <v-card-text class="text-center">
                <h3 class="mb-1">Knr.: {{ item.customerNumber }}</h3>
                <h1 class="font-weight-light mb-3 black--text">
                  {{ profile.salutation }} {{ profile.title }}
                  {{ profile.firstName }}
                  {{ profile.lastName }}
                </h1>
                <h2 class="mb-1 grey--text" v-if="profile.company">
                  {{ profile.company }}
                </h2>
                <h4 class="mb-1 grey--text">E-Mail: {{ item.email }}</h4>
                <v-row>
                  <v-col cols="12" sm="3">
                    <h3>Aktiv</h3>
                    <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
                    <v-icon v-else>mdi-close-circle</v-icon>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <h3>Erstellt</h3>
                    am <strong>{{ formatDateTime(item.createdAt, "L") }}</strong
                  ><br />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <h3>Aktualisiert</h3>
                    am <strong>{{ formatDateTime(item.updatedAt, "L") }}</strong
                  ><br />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <h3>Gesperrt</h3>
                    <v-icon v-if="item.locked">mdi-check-circle</v-icon>
                    <v-icon v-else>mdi-close-circle</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-5" flat>
              <v-card-text>
                <template v-if="addressesLoaded">
                  <AddressList :addressObjects="this.addressObjects" />
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
                <v-btn color="primary" dark @click="addressDialogControl()">
                  {{ $t("AddAddress") }}
                </v-btn>
                <AddressDialog
                    v-model="showAddressDialogForm"
                    :address-item="{ country: 'DE' }"
                    :handle-created="onAddressCreated"
                    :handle-deleted="onAddressDeleted"
                    :show-handle="showAddressDialogForm"
                    :title="$t('AddAddress')"
                    @close="addressDialogControl(true)"
                />
              </v-card-text>
            </v-card>
            <v-card class="mt-5" flat>
              <v-card-text>
                <ContactList
                    :contact-objects="contactsObjects"
                    :contacts-server-length="profile.contacts.length"
                    :handle-created="onContactCreated"
                    :handle-deleted="onContactDeleted"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="mt-5">
              <v-card-title>{{ $t("Seminars") }}</v-card-title>
              <v-card-text>
                <template v-if="participants.length">
                  <v-data-table
                      :headers="seminarHeaders"
                      :items="participants"
                      class="elevation-1"
                      item-key="@id"
                  >
                    <template
                        slot="item.seminarInfos.firstSeminarModuleStartDate"
                        slot-scope="{ item }"
                    >
                      <template v-if="item.seminarInfos">
                        {{ formatDateTime(item.seminarInfos.firstSeminarModuleStartDate) }}
                      </template>
                    </template>
                    <template
                        slot="item.deletedAt"
                        slot-scope="{ item }"
                    >
                      <span v-if="item.deletedAt" style="color:red">{{ $t('cancelled') }}</span>
                      <span v-else style="color:green">{{ $t('signedup') }}</span>
                    </template>
                    <ActionCell
                        :handle-show="() => showSeminar(props.item)"
                        slot="item.action"
                        slot-scope="props"
                    ></ActionCell>
                  </v-data-table>
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="mt-5">
              <v-card-title>{{ $t("Invoices") }}</v-card-title>
              <v-card-text>
                <template v-if="invoicesLoaded">
                  <v-data-table
                      :headers="invoiceHeaders"
                      :items="invoiceObjects"
                      class="elevation-1"
                      item-key="@id"
                  >
                    <template slot="item.customer" slot-scope="{ item }">
                      {{ getCustomerItem(item["customer"], "customerNumber") }}
                    </template>
                    <template slot="item.date" slot-scope="{ item }">
                      {{ formatDateTime(item["date"], "L") }}
                    </template>
                    <ActionCell
                        :handleShow="() => showInvoice(props.item)"
                        slot="item.action"
                        slot-scope="props"
                    ></ActionCell>
                  </v-data-table>
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ActionCell from "@/components/ActionCell";
import AddressDialog from "@/components/address/AddressDialog";
import AddressList from "@/components/address/AddressList";
import AddressMixin from "@/mixins/AddressMixin";
import ContactList from "@/components/contact/ContactList";
import ContactMixin from "@/mixins/ContactMixin";
import CustomerMixin from "@/mixins/CustomerMixin";
import InvoiceMixin from "@/mixins/InvoiceMixin";
import Loading from "../../components/Loading";
import ParticipantMixin from "@/mixins/ParticipantMixin";
import ProfileMixin from "@/mixins/ProfileMixin";
import SeminarMixin from "@/mixins/SeminarMixin";
import SeminarModuleMixin from "@/mixins/SeminarModuleMixin";
import ShowMixin from "../../mixins/ShowMixin";
import Toolbar from "../../components/Toolbar";

const servicePrefix = "Customer";

export default {
  name: "CustomerShow",
  servicePrefix,
  components: {
    ActionCell,
    AddressDialog,
    AddressList,
    ContactList,
    Loading,
    Toolbar,
  },
  mixins: [
    AddressMixin,
    ContactMixin,
    CustomerMixin,
    InvoiceMixin,
    ParticipantMixin,
    ProfileMixin,
    SeminarMixin,
    SeminarModuleMixin,
    ShowMixin,
  ],
  data() {
    return {
      participants: [],
      tab: true,
      isLoadingProfile: false,
      isLoadingAddresses: false,
      showAddressDialogForm: [],
      invoiceHeaders: [
        {
          text: this.$i18n.t("type"),
          value: "type",
          sortable: false,
          align: "left",
        },
        {
          text: this.$t("invoiceNumber"),
          value: "number",
        },
        { text: this.$i18n.t("customerNumber"), value: "customer" },
        { text: this.$i18n.t("invoiceDate"), value: "date" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      seminarHeaders: [
        { text: this.$i18n.t("name"), value: "seminarInfos.name" },
        { text: this.$i18n.t("Number"), value: "seminarInfos.number" },
        { text: this.$i18n.t("Status"), value: "deletedAt" },
        {
          text: this.$i18n.t("firstSeminarModuleStartDate"),
          value: "seminarInfos.firstSeminarModuleStartDate",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapFields("customer", {
      isLoading: "isLoading",
    }),
    ...mapGetters("customer", ["find"]),
    addressesLoaded() {
      if (Array.isArray(this.profile.addresses) === false) {
        return true;
      }
      return this.profile.addresses.length === this.addressObjects.length;
    },
    invoicesLoaded() {
      if (Array.isArray(this.invoices) === false) {
        return true;
      }
      return this.item.invoices.length === this.invoiceObjects.length;
    },
    participantsLoaded() {
      if (Array.isArray(this.participants) === false) {
        return true;
      }
      return this.item.participants.length === this.participantObjects.length;
    },
    addressObjects() {
      if (Array.isArray(this.profile.addresses) === false) {
        return [];
      }
      return this.profile.addresses.map((addressId) =>
          this.resolveAddress(addressId)
      ).filter((address) => address);
    },
    contactsObjects() {
      if (Array.isArray(this.profile.contacts) === false) {
        return [];
      }
      return this.profile.contacts.map((contactId) =>
          this.resolveContact(contactId)
      ).filter((contact) => contact);
    },
    invoiceObjects() {
      if (Array.isArray(this.item.invoices) === false) {
        return [];
      }
      return this.item.invoices.map((invoiceId) =>
          this.resolveInvoice(invoiceId)
      ).filter((invoice) => invoice);
    },
    participantObjects() {
      if (Array.isArray(this.item.participants) === false) {
        return [];
      }
      return this.item.participants.map((participantId) => {
        return this.resolveParticipant(participantId)
      }).filter((participant) => participant);
    },
    profile() {
      if (
          this.profileCreate &&
          typeof this.item.profile === "object" &&
          this.item.profile !== null
      ) {
        // create object, keep it simple
        return this.item.profile;
      }
      if (typeof this.item.profile !== "undefined") {
        return this.resolveProfile(this.item.profile);
      }
      return null;
    },
  },
  methods: {
    ...mapActions("customer", {
      deleteItem: "del",
      reset: "reset",
      retrieve: "load",
    }),
    ...mapActions('participant', {
      getParticipants: 'fetchAll',
    }),
    async loadAllParticipants() {
        this.participants = await this.getParticipants({
        'show_all_participants': 1,
        'customer': this.item['@id']
      })
    },
    onAddressCreated(createdAddress) {
      this.profile.addresses.push(createdAddress["@id"]);

      if (this.profile["@id"]) {
        let fakeItem = {
          "@id": this.profile["@id"],
          addresses: this.profile.addresses,
        };
        this.updateProfile(fakeItem);
      } else {
        // this case should not occur! But this is to cover this case if it ever happens that there is no profile
        this.createProfile({
          addresses: this.profile.addresses,
          customer: this.item["@id"],
        });
        this.$router.go();
      }
    },
    onContactCreated(createdContact) {
      this.profile.contacts.push(createdContact["@id"]);

      if (this.profile["@id"]) {
        let fakeItem = {
          "@id": this.profile["@id"],
          contacts: this.profile.contacts,
        };
        this.updateProfile(fakeItem);
      } else {
        // this case should not occur! But this is to cover this case if it ever happens that there is no profile
        this.createProfile({
          contacts: this.profile.contacts,
          customer: this.item["@id"],
        });
        this.$router.go();
      }
    },
    onAddressDeleted(deletedAddress) {
      // TODO: Call early, prevent not found
      this.removeProfileRelated(
          this.profile,
          "addresses",
          deletedAddress["@id"]
      );
    },
    addressDialogControl(close = false) {
      if (!close) {
        this.showAddressDialogForm.push("new");
      } else {
        this.showAddressDialogForm.splice(
            this.showAddressDialogForm.indexOf("new"),
            1
        );
      }
    },
    showInvoice(invoiceItem) {
      this.$router.push({
        name: "InvoiceUpdate",
        params: { id: invoiceItem["@id"] },
      });
    },
    showSeminar(participantItem) {
      this.$router.push({
        name: "SeminarShow",
        params: { id: `/seminars/${ participantItem?.seminarInfos.id }` },
      });
    },
    onContactDeleted(contact) {
      this.removeProfileRelated(this.profile, "contacts", contact["@id"]);
    },
  },
  async created() {
    this.loadAllParticipants()
  }
};
</script>