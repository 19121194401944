import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import remove from 'lodash/remove';
import ContactMixin from '@/mixins/ContactMixin';
import AddressMixin from '@/mixins/AddressMixin';

export default {
  mixins: [ContactMixin, AddressMixin],
  data() {
    return {
      loadingProfiles: []
    };
  },
  computed: {
    ...mapFields('profile', {
      profileIds: 'allIds',
      profileIdsCached: 'cacheAllIds',
      isProfileLoading: 'isLoading',
      errorProfile: 'error',
      updatedProfile: 'updated',
      violationsProfile: 'violations',
      createdProfile: 'created'
    }),
    ...mapGetters('profile', {
      findProfile: 'find'
    })
  },
  methods: {
    ...mapActions('profile', {
      retrieveProfile: 'load',
      updateProfile: 'update',
      createProfile: 'create',
      resetAllProfileData: 'resetAllData'
    }),
    resolveProfile(itemId) {
      if (itemId != null) {
        if (typeof itemId === 'object') {
          itemId = itemId['@id'];
        }
        if (!this.isProfileLoaded(itemId) && !this.loadingProfiles.includes(itemId)) {
          this.loadingProfiles.push(itemId);
          this.retrieveProfile(decodeURIComponent(itemId));
        }
        return (
          this.findProfile(itemId) || {
            addresses: [],
            contacts: []
          }
        );
      }
    },
    getProfileItem(profileIri, type) {
      if (profileIri !== null && profileIri) {
        if (typeof profileIri === 'object') {
          profileIri = profileIri['@id'];
        }
        let profile = this.resolveProfile(profileIri);
        if (profile) {
          return profile[type];
        }
      }
      return '';
    },
    getProfileItemBasics(profileIri, type) {
      if (profileIri !== null && profileIri) {
        if (typeof profileIri === 'object') {
          return profileIri[type]; // it is already resolved
        }
        let profile = this.resolveProfile(profileIri);
        if (profile) {
          return profile[type];
        }
      }
      return '';
    },
    getProfileName(profileIri) {
      if (profileIri !== null && profileIri) {
        if (typeof profileIri === 'object') {
          return `${profileIri.firstName} ${profileIri.lastName}`;
        }
        let profile = this.resolveProfile(profileIri);
        if (profile) {
          return `${profile.firstName} ${profile.lastName}`;
        }
      }
      return '';
    },
    removeProfileRelated(item, type, relatedIri) {
      item[type] = remove(item[type], function (n) {
        return !(n === relatedIri);
      });
    },
    isProfileLoaded(itemId) {
      return this.profileIdsCached.includes(itemId);
    },
    resolveProfileAsync(itemId) {
      const poll = (resolve) => {
        if (this.isProfileLoaded(itemId)) {
          resolve(this.resolveProfile(itemId));
        } else {
          this.resolveProfile(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      };
      return new Promise(poll);
    },
    clearProfileData() {
      this.loadingProfiles = [];
      this.resetAllProfileData();
      this.clearAddressData();
      this.clearContactData();
    }
  }
};
