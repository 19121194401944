<template>
  <v-app id="inspire">
    <snackbar></snackbar>
    <v-navigation-drawer
      app
      permanent
      v-if="loggedIn"
      v-model="drawer"
    >
      <v-list
        dark
        nav
        color="mainAccent"
        dense
        class="drawer-shadow"
        outlined
      >
        <v-list-item
          class="pl-3 py-0"
          v-if="profileLoaded"
        >
          <template v-if="false">
            <v-list-item-avatar v-if="this.userData.profileImage">
              <v-img :src="this.userData.profileImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <img
                src="./assets/profile-dummy.svg"
                alt="profile dummy image"
              />
            </v-list-item-avatar>
          </template>
          <v-list-item-content>
            <v-list-item-title>
              Willkommen, <br />
              {{ this.userData.title }} {{ this.userData.firstName }} {{ this.userData.lastName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list flat>
        <template v-for="menuItem in mainMenu">
          <template v-if="menuItem.items">
            <v-list-group
              class="v-list-group--small-padding"
              no-action
              v-if="menuItem.hasAccess"
              :prepend-icon="menuItem.icon"
              :key="menuItem.title"
              v-model="menuItem.active"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
              </template>
              <template v-for="subItem in menuItem.items">
                <v-list-item
                  v-if="subItem.hasAccess"
                  :to="{ name: subItem.route }"
                  :disabled="subItem.disabled ? subItem.disabled : false"
                  :key="subItem.title"
                >
                  <v-list-item-icon v-if="subItem.icon">
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item
              v-if="menuItem.hasAccess"
              :to="{ name: menuItem.route }"
              :disabled="menuItem.disabled ? menuItem.disabled : false"
              :key="menuItem.title"
              :class="menuItem.active ? 'primary--text' : ''"
            >
              <v-list-item-icon v-if="menuItem.icon">
                <v-icon>{{ menuItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      v-if="loggedIn"
      app
      dark
      color="mainAccent"
    >
      <v-toolbar-title>erica gilb AKADEMIEN</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            outlined
            :style="tenantSwitchButtonStyle"
          >
            <v-icon>mdi-domain</v-icon>
            <span v-if="hasActiveTenant">{{ userData.activeTenant.name }}</span>
            <span v-else>Mandanten</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="tenant in tenants"
            :key="tenant.name"
            @click.stop="tenantSwitch(tenant, true)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ tenant.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="hasRole('ROLE_SUPER_ADMIN')"
                @click.stop="tenantEdit(tenant)"
                icon
              >
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="tenantSwitch(null)">
            {{ $t("No-Tenant") }}
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :to="{ name: 'TenantCreate' }"
            v-if="hasRole('ROLE_CREATE_TENANT')"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t("Tenant Create") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="profileButtonPressed">
            <v-list-item-action>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="reloadDataButtonPressed">
            <v-list-item-action>
              <v-icon>mdi-reload</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Daten aktualisieren</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="signoutButtonPressed">
            <v-list-item-action>
              <v-icon>logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <Breadcrumb
        v-if="loggedIn"
        layout-class="pl-3 py-3"
      />
      <router-view></router-view>
    </v-main>
    <v-footer
      app
      dark
      color="mainAccent"
    >
      <span>&copy; erica gilb AKADEMIEN {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
  import Snackbar from "./components/Snackbar";
  import Breadcrumb from "./components/Breadcrumb";
  import AuthenticationMixin from "./mixins/AuthenticationMixin";
  import { mapActions } from "vuex";
  import TenantMixin from "@/mixins/TenantMixin";

  export default {
    mixins: [AuthenticationMixin, TenantMixin],
    components: {
      Snackbar,
      Breadcrumb,
    },
    data: () => ({
      drawer: null,
    }),
    computed: {
      mainMenu() {
        return [
          {
            title: this.$t("Dashboard"),
            route: 'Dashboard',
            icon: 'mdi-home',
            hasAccess: true,
            active: true,
          },
          {
            title: this.$t("Customers"),
            route: 'CustomerList',
            icon: 'mdi-account-multiple',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            disabled: !this.hasActiveTenant,
            active: false,
          },
          {
            title: this.$t("accounting"),
            icon: 'mdi-file-multiple',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
            items: [
              {
                title: this.$t("Invoices"),
                route: 'InvoiceList',
                icon: 'mdi-file-document-multiple',
                hasAccess: this.hasRole('ROLE_ADMIN'),
                disabled: !this.hasActiveTenant
              },
              {
                title: this.$t("Credits"),
                route: 'CreditList',
                icon: 'mdi-cash-plus',
                hasAccess: this.hasRole('ROLE_ADMIN'),
                disabled: !this.hasActiveTenant
              },
              {
                title: this.$t("Offers"),
                route: 'OfferList',
                icon: 'mdi-offer',
                hasAccess: this.hasRole('ROLE_ADMIN') && this.isOfferable,
                disabled: !this.hasActiveTenant
              },
            ],
          },
          {
            title: this.$t("seminars"),
            route: 'SeminarList',
            icon: 'mdi-human-male-board',
            hasAccess: this.hasRole('ROLE_READ_SEMINAR'),
            disabled: !this.hasActiveTenant,
            active: false,
          },
          {
            title: this.$t("SeminarPackages"),
            route: 'SeminarPackageList',
            icon: 'mdi-package-variant',
            hasAccess: this.hasRole('ROLE_READ_SEMINAR'),
            disabled: !this.hasActiveTenant,
            active: false,
          },
          {
            title: this.$t("Addons"),
            route: 'AddonList',
            icon: 'mdi-plus-box',
            hasAccess: this.hasRole('ROLE_READ_SEMINAR'),
            disabled: !this.hasActiveTenant,
            active: false,
          },
          {
            title: this.$t("Speakers"),
            icon: 'mdi-account-voice',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
            items: [
              {
                title: this.$t("SpeakerList"),
                icon: 'mdi-view-list',
                route: 'SpeakerList',
                hasAccess: this.hasRole('ROLE_ADMIN'),
              },
              {
                title: this.$t("SpeakerCalendar"),
                icon: 'mdi-calendar-month',
                route: 'SpeakerCalendar',
                hasAccess: this.hasRole('ROLE_ADMIN'),
              },
            ]
          },
          {
            title: this.$t("Issues"),
            route: 'IssueList',
            icon: 'mdi-alert-circle-outline',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
          },
          {
            title: this.$t("CMS"),
            icon: 'mdi-content-save-edit',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
            items: [
              {
                title: this.$t("Pages"),
                route: 'PageList',
                icon: 'mdi-file-document-multiple',
                hasAccess: this.hasRole('ROLE_ADMIN'),
                disabled: !this.hasActiveTenant
              },
              {
                title: this.$t("Menus"),
                route: 'MenuList',
                icon: 'mdi-menu',
                hasAccess: this.hasRole('ROLE_ADMIN'),
                disabled: !this.hasActiveTenant
              },
            ]
          },
          {
            title: this.$t("Basics"),
            icon: 'mdi-account-group',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
            items: [
              {
                title: this.$t("Tenant-Groups"),
                route: 'TenantGroupList',
                hasAccess: this.hasRole('ROLE_SUPER_ADMIN'),
              },
              {
                title: this.$t("Backend-Groups"),
                route: 'BackendGroupList',
                hasAccess: this.hasRole('ROLE_SUPER_ADMIN'),
              },
              {
                title: this.$t("Backend-Users"),
                route: 'BackendUserList',
                hasAccess: this.hasRole('ROLE_SUPER_ADMIN'),
              },
              {
                title: this.$t("TextTemplates"),
                route: 'TextTemplatesList',
                hasAccess: this.hasRole('ROLE_ADMIN'),
                disabled: !this.hasActiveTenant
              },
              {
                title: this.$t("Topics"),
                route: 'TopicList',
                hasAccess: this.hasRole('ROLE_ADMIN'),
                disabled: !this.hasActiveTenant
              },
            ]
          },
          {
            title: this.$t("Venues"),
            route: 'VenueList',
            icon: 'mdi-billboard',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
          },
          {
            title: this.$t("Newsletter"),
            route: 'NewsletterSubscriptionList',
            icon: 'mdi-email-newsletter',
            hasAccess: this.hasRole('ROLE_ADMIN'),
            active: false,
          },
        ];
      },
      tenants: function () {
        return Object.values(this.$store.state.tenant.byId);
      },
      hasActiveTenant() {
        if (typeof this.userData.activeTenant === "undefined") {
          return false;
        }
        return !(this.userData.activeTenant == null);
      },
      isOfferable() {
        if (this.currentSeminarSettings) {
          return this.currentSeminarSettings.offerable;
        }
        return false;
      },
      tenantSwitchButtonStyle() {
        const tenantButtonStyle = [{ minWidth: '200px' }, { justifyContent: 'start' }];
        if (this.userData.activeTenant) {
          const companySettings = this.getCompanySettings(this.userData.activeTenant);
          if (companySettings && companySettings.color) {
            tenantButtonStyle.push({ background: companySettings.color });
            tenantButtonStyle.push({ color: this.isHexLight(companySettings.color) ? '#000' : '#fff' });
          }
        }
        return tenantButtonStyle;
      },
    },
    methods: {
      log(message) {
        console.log(message);
      },
      ...mapActions({
        getTenants: "tenant/fetchAllIntoCache",
        getBackendUsers: "backendUser/fetchAllIntoCache",
        getBackendGroups: "backendGroup/fetchAllIntoCache",
        getFrontendGroups: "frontendGroup/fetchAllIntoCache",
        getSpeakers: "speaker/fetchAllIntoCache",
        getSeminars: "seminar/fetchAllIntoCache",
        getIssues: "issue/fetchAllIntoCache",
        getCustomers: "customer/fetchAllIntoCache",
        getTextTemplates: "textTemplate/fetchAllIntoCache",
        getVenues: "venue/fetchAllIntoCache",
        getTopics: "topic/fetchAllIntoCache",
        getAddons: "addon/fetchAllIntoCache",
        getAttendanceListTemplates: "attendanceListTemplate/fetchAllIntoCache",
      }),
      signoutButtonPressed() {
        this.$store.dispatch("logout").then(() => {
          window.localStorage.clear();
        });
        this.$router.push({ name: "login" });
      },
      profileButtonPressed() {
        if (this.userData.profileIri) {
          this.$router.push({
            name: "ProfileShow",
            params: { id: this.userData.profileIri },
          });
        } else {
          this.$router.push({ name: "ProfileCreate" });
        }
      },
      reloadDataButtonPressed() {
        this.loadInitialGlobalData();
        this.loadInitialTenantRelatedData();
      },
      tenantSwitch(tenant, redirect = false) {
        let userIri = this.userData.iri;
        this.$store.dispatch("authentication/selectTenant", {
          userIri,
          tenant,
          redirect,
        });
      },
      tenantEdit(tenant) {
        this.tenantSwitch(tenant, false);
        this.$router.push({ name: "TenantShow", params: { id: tenant["@id"] } });
      },
      loadInitialGlobalData() {
        this.getTenants();
        this.getAttendanceListTemplates({ pagination: false });
        this.getBackendUsers({ pagination: false });
        this.getBackendGroups({ pagination: false });
        this.getSpeakers({ pagination: false });
        this.getIssues({ pagination: false });
        this.getTextTemplates({ pagination: false });
        this.getVenues({ pagination: false });
        this.getAddons({ pagination: false });
      },
      loadInitialTenantRelatedData() {
        this.getSeminars({ pagination: true, itemsPerPage: 50 });
        this.getTopics({ pagination: false });
        this.getFrontendGroups({ pagination: false });
        this.getCustomers({ pagination: true, itemsPerPage: 50 });
      },
      isHexLight(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substring(0, 0 + 2), 16);
        const c_g = parseInt(hex.substring(2, 2 + 2), 16);
        const c_b = parseInt(hex.substring(4, 4 + 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
      }
    },
    watch: {
      loggedIn(loginStatus) {
        if (loginStatus) {
          // load global data when logged in
          this.loadInitialGlobalData();
          if (this.userData.activeTenant) {
            this.loadInitialTenantRelatedData();
          }
        }
      },
      userData: function (newUserData, oldUserData) {
        if (
          this.userData.activeTenant &&
          newUserData.activeTenant !== oldUserData.activeTenant
        ) {
          this.loadInitialTenantRelatedData();
        }
      },
      $route(to) {
        let active = null;
        switch (to.name) {
          case 'InvoiceList':
          case 'CreditList':
          case 'OfferList':
            active = this.$t("accounting");
            break;
          case 'SeminarList':
            active = this.$t("Seminars");
            break;
          case 'SeminarPackageList':
            active = this.$t("SeminarPackages");
            break;
          case 'AddonList':
            active = this.$t("Addons");
            break;
          case 'IssueList':
            active = this.$t("Issues");
            break;
          case 'PageList':
          case 'MenuList':
            active = this.$t("CMS");
            break;
          case 'SpeakerList':
          case 'SpeakerCalendar':
            active = this.$t("Speakers");
            break;
          case 'TenantGroupList':
          case 'BackendGroupList':
          case 'BackendUserList':
          case 'TextTemplatesList':
          case 'TopicList':
            active = this.$t("Basics");
            break;
        }
        if (active !== null) {
          this.mainMenu.forEach((menuItem) => {
            if (menuItem.title === active) {
              menuItem.active = true;
            } else {
              menuItem.active = false;
            }
          })
        }
      }
    },
    created() {
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$i18n.locale);
    },
  };
</script>
<style lang="scss">
  .v-application {
    .v-navigation-drawer.mainAccent .v-list-item {
      color: #fff;
      text-decoration: none;

      &:hover {
        .v-list-item__title {
          text-decoration: underline;
        }
      }
    }

    .v-list-group.v-list-group--small-padding>.v-list-group__items>.v-list-item {
      padding-left: 42px;
    }

    .drawer-shadow {
      border: none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }
  }
</style>
